import Data from './Data';

export default class UI {

    static DARK_THEME = 'dark';
    static LIGHT_THEME = 'light';

    cellSize = 5;
    borderWidth = 1;
    interval = 500;
    themeId = UI.LIGHT_THEME;
    themes = {
        light: {
            fill: {
                alive: '#ddd',
                dead: '#fefefe',
            },
            outline: '#f5f5f5',
        },
        dark: {
            fill: {
                alive: '#373737',
                dead: '#222',
            },
            outline: '#1d1d1d',
        },
    };
    conf = {};
    #on = false;

    constructor(el, canvas, conf = {}) {

        this.canvas = canvas;
        this.canvas.width = el.clientWidth;
        this.canvas.height = el.clientHeight;
        this.drawCtx = this.canvas.getContext('2d');
        this.setConf(conf);
        this.max = {
            x: Math.floor(this.canvas.width / (this.cellSize + this.borderWidth)),
            y: Math.floor(this.canvas.height / (this.cellSize + this.borderWidth)),
        };
    }

    setConf(conf = {}) {

        if ('cellSize' in conf) {
            this.cellSize = conf.cellSize;
        }
        if ('borderWidth' in conf) {
            this.borderWidth = conf.borderWidth;
        }
        if ('interval' in conf) {
            this.interval = conf.interval;
        }
        if ('themes' in conf) {
            this.themes = conf.themes;
        }
        if (
            'theme' in conf &&
            [ UI.DARK_THEME, UI.LIGHT_THEME ].includes(conf.theme)
        ) {
            this.themeId = conf.theme;
        }
    }

    seed(sources) {

        this.data = new Data(
            {
                x: this.max.x,
                y: this.max.y,
            },
            sources
        );
    }

    get theme() {
        if (this.themeId in this.themes) {
            return this.themeId;
        }
    }

    randomCoordinate() {
        return {
            x: Math.floor(Math.random() * this.max.x),
            y: Math.floor(Math.random() * this.max.y),
        };
    }

    setTheme(themeId) {
        if (themeId in this.themes) {
            this.themeId = themeId;
            this.draw();
        }
    }

    drawCell(x, y, v) {

        if (typeof v === 'boolean') {
            this.drawCtx.fillStyle = v ?
                this.themes[ this.themeId ].fill.alive :
                this.themes[ this.themeId ].fill.dead;

        } else if (v === undefined) {
            this.drawCtx.fillStyle = this.themes[ this.themeId ].fill.dead;

        } else {
            let val = v;
            if (Number.isInteger(v)) {
                val = '#' + (1 << 24 | v << 16 | v << 8 | v).toString(16).slice(1);
            }
            this.drawCtx.fillStyle = val;
        }

        this.drawCtx.fillRect(
            this.borderWidth + x * (this.borderWidth + this.cellSize),
            this.borderWidth + y * (this.borderWidth + this.cellSize),
            this.cellSize,
            this.cellSize
        );
    }

    fillBackground() {
        this.drawCtx.fillStyle = this.themes[ this.themeId ].outline;
        this.drawCtx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }

    draw() {

        this.fillBackground();

        for (let i = 0; i < this.max.x; i++) {
            for (let j = 0; j < this.max.y; j++) {
                this.drawCell(i, j, this.data.at(i, j));
            }
        }
    }

    fill(lifeArr) {

        let q = [];

        for (let lifeObj of lifeArr) {

            const { x, y, data } = lifeObj;

            q = this.data.from(data, x, y);

            for (let [ x, y, v ] of q) {
                this.drawCell(x, y, v);
            }
        }
    }

    step() {
        return this.data.next((x, y, v) => this.drawCell(x, y, v));
    }

    stepAsync() {
        return new Promise(resolve => resolve(this.step()));
    }

    timeoutAsync() {
        return new Promise(resolve => setTimeout(() => resolve(-1), this.interval));
    }

    iter() {

        if (!this.#on) return;

        const stepP = this.stepAsync();
        const timeoutP = this.timeoutAsync();

        Promise.race([ stepP, timeoutP ]).then(value => {
            if (value === -1) {
                stepP.then(() => this.iter());
            } else {
                timeoutP.then(() => this.iter());
            }
        });
    }

    start() {
        this.#on = true;
        this.iter();
    }

    pause() {
        this.#on = false;
    }

    togglePlay() {
        if (this.#on) {
            this.pause();
        } else {
            this.start();
        }
    }

    rotate(rotateCells) {

        this.drawCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        let tmp = this.canvas.width;
        this.canvas.width = this.canvas.height;
        this.canvas.height = tmp;

        tmp = this.max.x;

        this.max.x = this.max.y;
        this.max.y = tmp;
        this.data.rotate(rotateCells);
        this.draw();
    }
}

const conv = (str) => {

    let trunc = str.slice(0, str.indexOf('#'));

    let newRows = [];

    for (let row of trunc.split('$')) {

        let newRow = [];

        for (let cellStr of row.split(';')) {

            let hexStr, n = 1;

            if (cellStr.indexOf('.') > 0) {
                let [ d, h ] = cellStr.split('.');
                n = parseInt(d);
                hexStr = h;
            } else {
                hexStr = cellStr;
            }

            let v = parseInt(hexStr, 16);
            
            if (v !== 255) {
                v = 255 - v;

                v = v + 50;
                if (v > 180) {
                    v = 180;
                }
            }

            v = v.toString(16);

            newRow.push((n > 1) ? n.toString() + '.' + v : v);
        }

        newRows.push(newRow.join(';'));
    }

    return newRows.join('$') + '#';
};
